/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect } from 'react';
import PackageDetail from '@paquery-team/lib-package-detail';
import { useHistory, useLocation } from 'react-router-dom';
import { notification } from 'antd';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import {
  packagesTypesGlobalsSelector,
  selectCountries,
} from 'redux/globals/selectors';
import { authProfile } from 'redux/auth/selectors';
import { actions as packageActions } from 'redux/packages/slice';
import useStore from 'redux/store/index';
import { actions } from 'redux/store/slice';
import { storeItemsSelector, storeLoadedSelector } from 'redux/store/selectors';
import { zoneItemsSelector } from 'redux/zones/selectors';
import { MARKETPLACE_OWNERTYPE } from 'constants/defaultValues';
import { STORE_ROLES, MARKETPLACE_ROLES } from 'constants/roles';
import SITELINKS from 'constants/sitelinks';
import API from 'constants/api';
import { getZoneFromAddress } from 'constants/operations';
import { PACKAGE, allowedSendPackageTypes } from 'constants/packages';
import PackageService from 'services/PackageService';
import rest from 'util/Api';

const validationSchema = Yup.object().shape({
  externalCode: Yup.string()
    .matches(/^\S+$/, 'El código de seguimiento no debe contener espacios.')
    .nullable(),
  caption: Yup.string().required('Especifique el contenido del paquete.'),
  estimatedCost: Yup.number().nullable(),
  detail: Yup.string().nullable(),
  packageSize: Yup.number().typeError(
    'Es necesario indicar el tamaño del paquete.',
  ),
  packageType: Yup.number().typeError('Es necesario elegir un tipo de envío'),
  deliveryTerm: Yup.number().when('packageType', {
    // deliveryTerm is required when packageType is send (2) or direct to consumer (4)
    is: (packageType) => packageType === 2 || packageType === 4,
    then: Yup.number().typeError('Es necesario elegir un plazo de entrega'),
    otherwise: Yup.number().nullable(),
  }),
  ownerID: Yup.number().typeError('Es necesario elegir un marketplace'),
  storeId: Yup.number().typeError('Es necesario elegir una tienda'),
  shippingScheduleDestination: Yup.object().shape({
    name: Yup.string().required('Indique el nombre del destinatario.'),
    phone: Yup.number().typeError('Teléfono invalido').nullable(),
    destinationEmail: Yup.string()
      .email('Ingrese un formato de correo válido.')
      .required('Ingrese una dirección de mail'),
    shippingAddress: Yup.object().shape({
      addressDetail: Yup.string().required('Indique la dirección de entrega'),
    }),
    deliveryNote: Yup.boolean().nullable(),
    paymentOnDelivery: Yup.boolean().nullable(),
    paymentAmount: Yup.number().when('paymentOnDelivery', {
      is: true,
      then: Yup.number()
        .required('Es necesario especificar un monto')
        .typeError('El monto debe ser un numero valido'),
      otherwise: Yup.number().nullable(),
    }),
  }),
  additionalCode: Yup.string().when('packageType', {
    // when package is type flex, additionalCode is required
    is: 5,
    then: Yup.string().required('Es necesario especificar el codigo de venta'),
    otherwise: Yup.string().nullable(),
  }),
});

const initialValues = (profile) => ({
  externalCode: '',
  status: null,
  estimatedCost: '0',
  caption: '',
  reason: '',
  detail: '',
  ownerID: profile.marketplace.id,
  storeId: STORE_ROLES.some((storeRole) => storeRole === profile.userRoleID)
    ? profile.store.id
    : null,
  packageSize: null,
  packageType: null,
  deliveryTerm: null,
  additionalCode: '',
  shippingScheduleOrigin: {
    name: '',
    comment: '',
    phone: '',
    shippingAddress: {
      addressDetail: '',
      geoKey: '',
      comment: '',
    },
  },
  shippingScheduleDestination: {
    name: '',
    phone: '',
    destinationEmail: '',
    comment: '',
    zone: null,
    shippingAddress: {
      addressDetail: '',
      geoKey: '',
      comment: '',
    },
  },
});

const disabledInputs = (profile) => ({
  status: true,
  reason: true,
  ownerID: true,
  shippingScheduleOrigin: {
    name: true,
    phone: true,
    shippingAddress: {
      addressDetail: true,
      geoKey: true,
    },
  },
  shippingScheduleDestination: {
    shippingAddress: {
      geoKey: true,
    },
  },
  storeId: STORE_ROLES.some((storeRole) => storeRole === profile.userRoleID),
});

const getPersonName = (fullName) => fullName.split(' ')[0];

const getPersonLastName = (fullName) => fullName.split(' ').slice(-1)[0];

const PackageSend = () => {
  useStore();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const profile = useSelector(authProfile);
  const zones = useSelector(zoneItemsSelector);
  const packageTypes = useSelector(packagesTypesGlobalsSelector);
  const stores = useSelector(storeItemsSelector);
  const countries = useSelector(selectCountries);
  const storeLoaded = useSelector(storeLoadedSelector);

  const [saving, setSaving] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [initialValuesDuplicate, setInitialValuesDuplicate] = useState(
    initialValues(profile),
  );

  useEffect(() => {
    const getPackage = async (id) => {
      try {
        const response = await PackageService.getPackage(id);
        setInitialValuesDuplicate({
          ...response,
          shippingScheduleDestination: {
            ...response.shippingScheduleDestination,
            zone: response.shippingScheduleDestination.zone?.id,
          },
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('error', err);
      }
    };
    if (location.state?.id) {
      getPackage(location.state.id);
    }
  }, [location]);

  useEffect(() => {
    if (STORE_ROLES.some((storeRole) => storeRole === profile.userRoleID)) {
      setStoreList([profile.store]);
    }
    if (
      MARKETPLACE_ROLES.some((marketRole) => marketRole === profile.userRoleID)
    ) {
      dispatch(actions.fetch());
    }
  }, [dispatch, profile.store, profile.userRoleID]);

  useEffect(() => {
    if (stores.length > 0) {
      setStoreList(stores);
    }
  }, [stores]);

  useEffect(() => {
    if (stores.length === 0 && storeLoaded) {
      notification.info({
        message:
          'Es necesario que cargues al menos una tienda para poder continuar',
        description: 'Te redirigiremos hacia el alta para que puedas continuar',
      });
      setTimeout(() => {
        history.push(SITELINKS.stores.add);
      }, 2000);
    }
  }, [stores, storeLoaded, history]);

  const getStoreSchedule = (store, origin) => ({
    addressDetail: store.address,
    addressInformation: {
      immediately: {
        status: true,
      },
      location: {
        lat: '',
        lng: '',
      },
    },
    comment: origin.comment,
    isImmediateDelivery: true,
    name: store.name,
    phone: store.phone,
    scheduledDate: '',
    shippingAddress: {
      address: store.address,
      addressDetail: store.address,
      comment: origin.shippingAddress.comment,
      lat: '',
      lng: '',
      phone: store.phone,
      w3w: '',
    },
  });

  const getPayload = async (values) => {
    const resolvedZone = await getZoneFromAddress(
      values.shippingScheduleDestination.shippingAddress.addressDetail,
      profile.logisticOperatorID,
    );
    if (!resolvedZone.id)
      notification.error({
        message: 'Oops!',
        description: 'No se ha encontrado una zona para esa dirección.',
      });
    return {
      ...values,
      ownerType: MARKETPLACE_OWNERTYPE,
      shippingScheduleDestination: {
        ...values.shippingScheduleDestination,
        zone: {
          id: values.shippingScheduleDestination.zone,
        },
        shippingAddress: {
          ...values.shippingScheduleDestination.shippingAddress,
          lat: resolvedZone.lat,
          lng: resolvedZone.lng,
          postalCode: resolvedZone.postalCode,
          resolutedAddress: resolvedZone.resolutedAddress,
          resolutedStreet: resolvedZone.resolutedStreet,
          resolutedStreetNumber: resolvedZone.resolutedStreetNumber,
          resolutedLocality: resolvedZone.resolutedLocality,
        },
      },
      user: {
        email: values.shippingScheduleDestination.destinationEmail,
        lastName: getPersonLastName(values.shippingScheduleDestination.name),
        name: getPersonName(values.shippingScheduleDestination.name),
        phone: values.shippingScheduleDestination.phone,
      },
    };
  };

  const submitEdition = async (values) => {
    setSaving(true);
    const payload =
      values.packageType === PACKAGE.TYPES.PICKUP
        ? await getPayload({
            ...values,
            shippingScheduleOrigin: values.shippingScheduleDestination,
          })
        : await getPayload({
            ...values,
            shippingScheduleOrigin: getStoreSchedule(
              profile.store ||
                stores.find((store) => store.id === values.storeId),
              values.shippingScheduleOrigin,
            ),
          });

    try {
      const response = await rest.post(API.packages.send, payload);
      if (rest.isSuccessResponse(response)) {
        const packet = response.data.data;
        notification.success({
          message: 'Envío de paquete',
          description: `El paquete ${packet.externalCode} ha sido creado correctamente.`,
        });
        dispatch(packageActions.refreshPage());
        setTimeout(() => {
          history.push(SITELINKS.packages.list);
        }, 1000);
      }
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al enviar el paquete${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
      setSaving(false);
    }
  };

  let filteredPackagesTypes;
  if (packageTypes) {
    filteredPackagesTypes = packageTypes.filter((packageType) =>
      allowedSendPackageTypes.some(
        (sendingType) => sendingType === packageType.value,
      ),
    );
  }

  const handleBlur = async (destinationAddressValue, setFieldValue) => {
    if (!destinationAddressValue) return;
    try {
      const zone = await getZoneFromAddress(
        destinationAddressValue,
        profile.logisticOperatorID,
      );
      if (zone.id)
        setFieldValue(
          'shippingScheduleDestination.zone',
          parseInt(zone.id, 10),
        );
      else
        notification.error({
          message: 'Oops!',
          description: 'No se ha encontrado una zona para esa dirección.',
        });
    } catch (error) {
      notification.error({
        message: 'Oops!',
        description: 'Ha ocurrido un error. Intente de nuevo más tarde.',
      });
    }
  };

  return (
    <PackageDetail
      packageTypes={filteredPackagesTypes}
      marketplaces={[profile.marketplace]}
      stores={storeList}
      zones={zones}
      saving={saving}
      countries={countries}
      loaded={profile.store || storeLoaded}
      disabledInputs={disabledInputs(profile)}
      initialValues={initialValuesDuplicate}
      validationSchema={validationSchema}
      onSubmit={submitEdition}
      onBlurDeliveryAddress={handleBlur}
    />
  );
};

export default PackageSend;
