/* eslint-disable import/no-named-as-default-member */
import React, { useState, useEffect } from 'react';
import PackageDetail from '@paquery-team/lib-package-detail';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Result, notification } from 'antd';
import JsDownload from 'js-file-download';
import { useSelector, useDispatch } from 'react-redux';
import useStore from 'redux/store';
import { actions as storeActions } from 'redux/store/slice';
import { storeItemsSelector, storeLoadedSelector } from 'redux/store/selectors';
import { fetchPackage } from 'redux/packages/saga';
import { zoneItemsSelector } from 'redux/zones/selectors';
import { updateAllPacket } from 'redux/packageAll/actions';
import { authProfile } from 'redux/auth/selectors';
import {
  selectCountries,
  packagesStatusesGlobalsSelector,
} from 'redux/globals/selectors';
import {
  fetchPackage as fetchPackageById,
  getZoneFromAddress,
} from 'constants/operations';
import API from 'constants/api';
import { APP_ROLE_MARKETPLACE, APP_ROLE_STORE } from 'constants/roles';
import { ExportDateFormat } from 'constants/dateFormats';
import rest from 'util/Api';
import { PACKAGE, isArrivableStatus } from 'constants/packages';
import { IMAGE_REGEX } from 'constants/defaultValues';

const disabledInputs = {
  externalCode: true,
  caption: true,
  estimatedCost: true,
  ownerID: true,
  packageType: true,
  deliveryTerm: true,
  arrivedAtPaqueryPointDate: true,
  additionalCode: true,
  shippingScheduleDestination: {
    scheduledDate: true,
    name: true,
    destinationEmail: true,
    shippingAddress: {
      geoKey: true,
    },
    destinationAclaration: true,
    destinationDocNumber: true,
    tramitNumber: true,
  },
  shippingScheduleOrigin: {
    name: true,
    phone: true,
    shippingAddress: {
      addressDetail: true,
      geoKey: true,
    },
  },
  storeId: true,
};

const getAllowedStatus = (statusList, currentStatus) => {
  const allowedStatus = [];
  const currentStatusFullDescription = statusList.find(
    (status) => status.value === currentStatus,
  );
  allowedStatus.push(currentStatusFullDescription);
  if (currentStatus === PACKAGE.STATUSES.DELIVERYATTEMPTTWO) {
    const visitFirstTryFullDescription = statusList.find(
      (status) => status.value === PACKAGE.STATUSES.DELIVERYATTEMPTONE,
    );
    allowedStatus.push(visitFirstTryFullDescription);
    const devolutionProcessFullDescription = statusList.find(
      (status) => status.value === PACKAGE.STATUSES.INRETURNPROCESS,
    );
    allowedStatus.push(devolutionProcessFullDescription);
  }
  if (currentStatus === PACKAGE.STATUSES.DELIVERYATTEMPTONE) {
    const visitSecondTryFullDescription = statusList.find(
      (status) => status.value === PACKAGE.STATUSES.DELIVERYATTEMPTTWO,
    );
    allowedStatus.push(visitSecondTryFullDescription);
  }
  if (isArrivableStatus(currentStatus)) {
    const arrivedPaquerypointFullDescription = statusList.find(
      (status) => status.value === PACKAGE.STATUSES.ARRIVEDATPAQUERYPOINT,
    );
    allowedStatus.push(arrivedPaquerypointFullDescription);
  }
  if (currentStatus === PACKAGE.STATUSES.PENDINGENTRYPAQUERYPOINT) {
    const onPaqueryPointFullDescription = statusList.find(
      (status) => status.value === PACKAGE.STATUSES.LOGGEDINTOPAQUERY,
    );
    allowedStatus.push(onPaqueryPointFullDescription);
  }
  if (currentStatus === PACKAGE.STATUSES.CANCELLED) {
    const devolutionProcessFullDescription = statusList.find(
      (status) => status.value === PACKAGE.STATUSES.INRETURNPROCESS,
    );

    allowedStatus.push(devolutionProcessFullDescription);
  }
  const cancelledFullDescription = statusList.find(
    (status) => status.value === PACKAGE.STATUSES.CANCELLED,
  );
  allowedStatus.push(cancelledFullDescription);
  return allowedStatus;
};

const defaultPackageSchema = Yup.object().shape({
  status: Yup.number(),
  packageType: Yup.number(),
  reason: Yup.string().when('status', {
    is: 21,
    then: Yup.string()
      .required('Indique el motivo de la cancelación')
      .typeError('Indique el motivo de la cancelación'),
    otherwise: Yup.string().nullable(),
  }),
  shippingScheduleDestination: Yup.object().shape({
    shippingAddress: Yup.object().shape({
      addressDetail: Yup.string().required('Indique la dirección de entrega'),
    }),
    deliveryNote: Yup.boolean().nullable(),
    paymentOnDelivery: Yup.boolean().nullable(),
    paymentAmount: Yup.number().when('paymentOnDelivery', {
      is: true,
      then: Yup.number()
        .required('Es necesario especificar un monto')
        .typeError('El monto debe ser un numero valido'),
      otherwise: Yup.number().nullable(),
    }),
  }),
  additionalCode: Yup.string().when('packageType', {
    // when package is type flex, additionalCode is required
    is: 5,
    then: Yup.string().required('Es necesario especificar el codigo de venta'),
    otherwise: Yup.string().nullable(),
  }),
});

const useGetPacket = (id, externalCode, packageStatuses) => {
  const dispatch = useDispatch();
  const [fetchPacket, setFetchPacket] = useState(null);
  const [filteredStatus, setFilteredStatus] = useState([]);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    const getPacket = async () => {
      let packetResponse;
      if (id) {
        packetResponse = await fetchPackageById(id);
      } else if (externalCode) {
        packetResponse = await fetchPackage(externalCode, dispatch);
      } else {
        setErrors('Se necesita codigo externo o id para editar un paquete');
        return;
      }
      packetResponse.shippingScheduleDestination.zone =
        packetResponse.shippingScheduleDestination.zone?.id;
      if (packetResponse instanceof Error) {
        setErrors(packetResponse.message);
      } else {
        setFetchPacket(packetResponse);
        setFilteredStatus(
          getAllowedStatus(packageStatuses, packetResponse.status),
        );
      }
    };
    if (!fetchPacket) {
      getPacket();
    }
  }, [dispatch, externalCode, id, fetchPacket, packageStatuses]);

  return { packet: fetchPacket, errors, filteredStatus };
};

const useStoresForPackageEdit = () => {
  const dispatch = useDispatch();
  const [stores, setStores] = useState([]);
  const storeItems = useSelector(storeItemsSelector);
  const storesLoaded = useSelector(storeLoadedSelector);
  const profile = useSelector(authProfile);
  useEffect(() => {
    if (!storesLoaded && profile.AppRole === APP_ROLE_MARKETPLACE) {
      dispatch(storeActions.fetch());
    }
  }, [dispatch, storesLoaded, profile.AppRole]);
  useEffect(() => {
    if (storeItems?.length > 0) {
      setStores(storeItems);
    }
  }, [storeItems]);
  useEffect(() => {
    if (profile.AppRole === APP_ROLE_STORE) {
      setStores([profile.store]);
    }
  }, [profile]);
  return stores;
};

const PackageModify = () => {
  const { externalCode, id } = useParams();
  useStore();
  const dispatch = useDispatch();
  const history = useHistory();
  const stores = useStoresForPackageEdit();
  const packageStatuses = useSelector(packagesStatusesGlobalsSelector);
  const profileLogged = useSelector(authProfile);
  const zones = useSelector(zoneItemsSelector);
  const countries = useSelector(selectCountries);

  const { packet, errors, filteredStatus } = useGetPacket(
    id,
    externalCode,
    packageStatuses,
  );
  const [saving, setSaving] = useState(false);

  const parsePacket = async (values) => {
    const resolvedZone = await getZoneFromAddress(
      values.shippingScheduleDestination.shippingAddress.addressDetail,
      profileLogged.logisticOperatorID,
    );
    if (!resolvedZone.id)
      notification.error({
        message: 'Oops!',
        description: 'No se ha encontrado una zona para esa dirección.',
      });
    const builtPackage = {
      ...values,
      signatureImage: values.signatureFileName
        ? values.signatureImage.replace(IMAGE_REGEX, '')
        : undefined,
      signatureFileName: values.signatureFileName,
      shippingScheduleOrigin: {
        ...values.shippingScheduleOrigin,
        driver: packet.shippingScheduleOrigin?.driver,
      },
      shippingScheduleDestination: {
        ...values.shippingScheduleDestination,
        zoneID: values.shippingScheduleDestination.zone?.id,
        distributionZoneID:
          values.shippingScheduleDestination.distributionZone?.id,
        driver: packet.shippingScheduleDestination?.driver,
        shippingAddress: {
          ...values.shippingScheduleDestination.shippingAddress,
          lat: resolvedZone.lat,
          lng: resolvedZone.lng,
          postalCode: resolvedZone.postalCode,
          resolutedAddress: resolvedZone.resolutedAddress,
          resolutedStreet: resolvedZone.resolutedStreet,
          resolutedStreetNumber: resolvedZone.resolutedStreetNumber,
          resolutedLocality: resolvedZone.resolutedLocality,
        },
      },
      zone: {
        id: values.shippingScheduleDestination.zone,
      },
    };
    return builtPackage;
  };

  const submitEdition = async (values) => {
    setSaving(true);
    const putPackage = await parsePacket(values);

    try {
      const response = await rest.put(
        `${API.packages.get}/${putPackage.id}`,
        putPackage,
      );
      if (rest.isSuccessResponse(response)) {
        notification.success({
          message: 'Modificación de paquete correcto',
          description: `El paquete ${packet.externalCode} ha sido modificado correctamente.`,
        });
        dispatch(updateAllPacket(putPackage));
        setTimeout(() => {
          history.goBack();
        }, 1000);
      }
    } catch (error) {
      notification.error({
        message: 'Ocurrio un problema',
        description: `Ha ocurrido un error al modificar el paquete${
          error.message ? `: ${error.message}` : '.'
        }`,
      });
      setSaving(false);
    }
  };

  const handleDownloadRemito = async () => {
    try {
      // eslint-disable-next-line import/no-named-as-default-member
      const response = await rest.get(
        `${API.packages.downloadDeliveryNoteById(packet.id)}`,
        {
          responseType: 'blob',
        },
      );
      if (!rest.isSuccessResponse(response)) {
        // converts blob to text
        const error = await response.data.text();
        throw new Error(error);
      }
      const filename = `Remito ${packet.externalCode} ${dayjs()
        .tz()
        .format(ExportDateFormat)}.pdf`;
      JsDownload(response.data, filename);
    } catch (exception) {
      // eslint-disable-next-line no-console
      console.log(exception);
      notification.error({
        message: 'Ha ocurrido un error al traer el remito',
        description: `Intente nuevamente por favor o informelo a los administradores${
          exception.message ? ` - ${exception.message}` : '.'
        }`,
      });
    }
  };

  const handleBlur = async (destinationAddressValue, setFieldValue) => {
    if (!destinationAddressValue) return;
    try {
      const zone = await getZoneFromAddress(
        destinationAddressValue,
        profileLogged.logisticOperatorID,
      );
      if (zone.id)
        setFieldValue(
          'shippingScheduleDestination.zone',
          parseInt(zone.id, 10),
        );
      else
        notification.error({
          message: 'Oops!',
          description: 'No se ha encontrado una zona para esa dirección.',
        });
    } catch (error) {
      notification.error({
        message: 'Oops!',
        description: 'Ha ocurrido un error. Intente de nuevo más tarde.',
      });
    }
  };

  if (errors) return <Result status="warning" title={errors} />;

  return (
    <PackageDetail
      packageStatuses={filteredStatus}
      stores={stores}
      zones={zones}
      saving={saving}
      isModify
      countries={countries}
      loaded={!!packet}
      disabledInputs={disabledInputs}
      initialValues={packet}
      validationSchema={defaultPackageSchema}
      onSubmit={submitEdition}
      onDownloadRemito={handleDownloadRemito}
      onBlurDeliveryAddress={handleBlur}
    />
  );
};

PackageModify.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      packet: PropTypes.shape({
        prefix: PropTypes.string,
        country: PropTypes.number,
      }),
    }),
  }).isRequired,
};

export default PackageModify;
